exports.components = {
  "component---src-pages-agb-agb-js": () => import("./../../../src/pages/AGB/AGB.js" /* webpackChunkName: "component---src-pages-agb-agb-js" */),
  "component---src-pages-agb-index-js": () => import("./../../../src/pages/AGB/index.js" /* webpackChunkName: "component---src-pages-agb-index-js" */),
  "component---src-pages-company-company-js": () => import("./../../../src/pages/Company/Company.js" /* webpackChunkName: "component---src-pages-company-company-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/Company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-contact-contact-js": () => import("./../../../src/pages/Contact/Contact.js" /* webpackChunkName: "component---src-pages-contact-contact-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/Contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cookies-cookies-js": () => import("./../../../src/pages/Cookies/Cookies.js" /* webpackChunkName: "component---src-pages-cookies-cookies-js" */),
  "component---src-pages-cookies-index-js": () => import("./../../../src/pages/Cookies/index.js" /* webpackChunkName: "component---src-pages-cookies-index-js" */),
  "component---src-pages-home-components-clients-js": () => import("./../../../src/pages/Home/_components/Clients.js" /* webpackChunkName: "component---src-pages-home-components-clients-js" */),
  "component---src-pages-home-components-home-footer-js": () => import("./../../../src/pages/Home/_components/HomeFooter.js" /* webpackChunkName: "component---src-pages-home-components-home-footer-js" */),
  "component---src-pages-home-components-home-header-js": () => import("./../../../src/pages/Home/_components/HomeHeader.js" /* webpackChunkName: "component---src-pages-home-components-home-header-js" */),
  "component---src-pages-home-components-news-js": () => import("./../../../src/pages/Home/_components/News.js" /* webpackChunkName: "component---src-pages-home-components-news-js" */),
  "component---src-pages-home-components-services-js": () => import("./../../../src/pages/Home/_components/Services.js" /* webpackChunkName: "component---src-pages-home-components-services-js" */),
  "component---src-pages-home-components-tiles-js": () => import("./../../../src/pages/Home/_components/Tiles.js" /* webpackChunkName: "component---src-pages-home-components-tiles-js" */),
  "component---src-pages-home-home-js": () => import("./../../../src/pages/Home/Home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-imprint-imprint-js": () => import("./../../../src/pages/Imprint/Imprint.js" /* webpackChunkName: "component---src-pages-imprint-imprint-js" */),
  "component---src-pages-imprint-index-js": () => import("./../../../src/pages/Imprint/index.js" /* webpackChunkName: "component---src-pages-imprint-index-js" */),
  "component---src-pages-jobs-index-js": () => import("./../../../src/pages/Jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-jobs-jobs-js": () => import("./../../../src/pages/Jobs/Jobs.js" /* webpackChunkName: "component---src-pages-jobs-jobs-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/PrivacyPolicy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-privacy-policy-privacy-policy-js": () => import("./../../../src/pages/PrivacyPolicy/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-privacy-policy-js" */),
  "component---src-pages-services-hygiene-and-cleaning-hygiene-and-cleaning-js": () => import("./../../../src/pages/Services/HygieneAndCleaning/HygieneAndCleaning.js" /* webpackChunkName: "component---src-pages-services-hygiene-and-cleaning-hygiene-and-cleaning-js" */),
  "component---src-pages-services-hygiene-and-cleaning-index-js": () => import("./../../../src/pages/Services/HygieneAndCleaning/index.js" /* webpackChunkName: "component---src-pages-services-hygiene-and-cleaning-index-js" */),
  "component---src-pages-services-project-management-index-js": () => import("./../../../src/pages/Services/ProjectManagement/index.js" /* webpackChunkName: "component---src-pages-services-project-management-index-js" */),
  "component---src-pages-services-project-management-project-management-js": () => import("./../../../src/pages/Services/ProjectManagement/ProjectManagement.js" /* webpackChunkName: "component---src-pages-services-project-management-project-management-js" */),
  "component---src-pages-services-recruitment-index-js": () => import("./../../../src/pages/Services/Recruitment/index.js" /* webpackChunkName: "component---src-pages-services-recruitment-index-js" */),
  "component---src-pages-services-recruitment-recruitment-js": () => import("./../../../src/pages/Services/Recruitment/Recruitment.js" /* webpackChunkName: "component---src-pages-services-recruitment-recruitment-js" */)
}

